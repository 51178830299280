// import './styles/App.css';
import './styles/AppRedit.css';
import React, { useEffect, useState } from "react";
import { ethers, BigNumber } from "ethers";
import { BeatLoader, HashLoader } from 'react-spinners';
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from 'react-bootstrap';

import WalletAddress from './components/WalletAddress';
import LoadingIndicator from './components/LoadingIndicator';
import ProcessTx from './components/ProcessTx';

// Wallet
import { connectWallet,
         checkIfWalletIsConnected,
         handleChangeNetwork,
         handleLogout } from './utils/Wallet.js';

// Config
import data from './utils/data.json';

// Abi
import presale from './utils/PresaleStaged.json';
import token from './utils/Token.json';
import vault from './utils/TokenVesting.json';
import cdeposit from './utils/DepositStaged.json';
import staking from './utils/Staking.json';

// Constants
const logo = require('./assets/sidebar_logo_1.png');

// Env
const PRESALE_CONTRACT = `${process.env.REACT_APP_PRESALE_CONTRACT}`;
const TOKEN_CONTRACT = `${process.env.REACT_APP_TOKEN_CONTRACT}`;
const VAULT5_CONTRACT = `${process.env.REACT_APP_VAULT5_CONTRACT}`;
const VAULT2_CONTRACT = `${process.env.REACT_APP_VAULT2_CONTRACT}`;
const STAKING_CONTRACT = `${process.env.REACT_APP_STAKING_CONTRACT}`;
const DEPOSIT_CONTRACT = `${process.env.REACT_APP_DEPOSIT_CONTRACT}`;
const BUSD_CONTRACT = `${process.env.REACT_APP_BUSD_CONTRACT}`;
const CHAINID = `${process.env.REACT_APP_CHAINID}`;
const MONEY_MIN_AMOUNT = `${process.env.REACT_APP_MONEY_MIN_AMOUNT}`;
const MONEY_MAX_AMOUNT = `${process.env.REACT_APP_MONEY_MAX_AMOUNT}`;

console.log("CHAIN",CHAINID);
console.log("VAULT5",VAULT5_CONTRACT);
console.log("VAULT2",VAULT2_CONTRACT);
console.log("MIN",MONEY_MIN_AMOUNT);

const Deposit = () => {

  /*
   * Just a state variable we use to store our user's public wallet. Don't forget to import useState.
   */
  const [currentAccount, setCurrentAccount] = useState("");
  const [lang, setLang] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(0);
  const [steps, setSteps] = useState([]);
  const [money, setMoney] = useState(0);
  const [total, setTotal] = useState(0);
  const [alloc, setAlloc] = useState(0);
  const [busd, setBusd] = useState(0);
  const [ref, setRef] = useState("");
  const [pid, setPid] = useState(0);

  const getProject = () => {
    if (pid === 0) return 'Carbon Credits'
    else if (pid === 1) return 'Energy'
    else return 'Redit'
  }

  const connect = async () => {
    setProcessing(1);
    const getConnect = async () => {
        const acc = await connectWallet(lang);
        setCurrentAccount(acc);
    };
    getConnect();
    setProcessing(0);
  }

  const Logout = async () => {
    setCurrentAccount(null);
    //handleLogout();
  }

  const fetchAlloc = async () => {

    const { ethereum } = window;

    if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const Token = new ethers.Contract(BUSD_CONTRACT, token.abi, provider);
        const Contract = new ethers.Contract(DEPOSIT_CONTRACT, cdeposit.abi, provider);

        try {
            let a = await Contract.allocation(currentAccount);
            setAlloc(ethers.utils.formatEther(a));

            let b = await Contract.totalAllocated();
            setTotal(ethers.utils.formatEther(b));

            let c = await Token.balanceOf(currentAccount);
            setBusd(ethers.utils.formatEther(c));

        } catch (error) {
            console.log(error);
        }
    } else {
        console.log("Ethereum object doesn't exist!");
    }

    setLoading(false);

    console.log("currentAccount",currentAccount);
  };

  const sendDeposit = async () => {

      if (!money) { return }

      const { ethereum } = window;
      setProcessing(2);
      setSteps(['APPROVE','CONFIRM']);

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const Token = new ethers.Contract(BUSD_CONTRACT, token.abi, signer);
        const Contract = new ethers.Contract(DEPOSIT_CONTRACT, cdeposit.abi, signer);

        // Token approve
        // Staking deposit

        let tx1 = await Token.approve(DEPOSIT_CONTRACT, ethers.utils.parseEther(money));
        const tx1s = await tx1.wait();

        if (tx1s.status === 1) {
            toast.success("Transaction 1 OK");
        } else {
            toast.error("Transaction 1 FAILED");
            return
        }
        setProcessing(3);

        let tx2 = await Contract.allocate(ethers.utils.parseEther(money),pid,ref);
        const tx2s = await tx2.wait();

        if (tx2s.status === 1) {
           toast.success("Transaction 2 OK");
        } else {
            toast.error("Transaction 2 FAILED");
            return
        }

        await fetchAlloc();
        setProcessing(0);
      }
    }

  /*
  * This runs our function when the page loads.
  */
  useEffect(() => {
    setLoading(true);

    const getAccount = async () => {
        const acc = await checkIfWalletIsConnected();
        setCurrentAccount(acc);
    };
    getAccount();

  }, [])

  /*
  * Check Allocation
  */
  useEffect(() => {
    if (currentAccount) {
        fetchAlloc();
    }
  }, [currentAccount]);

  /*
  * Set language
  */
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const lang = params.get('lang'); // Returns "full"
    const ref = params.get('ref');
    const pid = Number(params.get('pid'));
    if(lang) setLang(lang.toLowerCase());
    if(pid) setPid(pid);
    if(ref) setRef(ref.toLowerCase().substring(0, 16));
  }, []);

  /*
  * We added a simple onClick event here.
  */
  const renderNotConnectedContainer = () => (
    <button onClick={connect} className="cta-button connect-wallet-button">
      {lang === "es" ? "Conectar billetera" : "Connect to Wallet"}
    </button>
  );

  /*
  * We want the "Connect to Wallet" button to dissapear if they've already connected their wallet!
  */
  const renderAllocateUI = () => {
    if (loading) return <LoadingIndicator />;

    return (
    <div>
    <WalletAddress currentAccount={currentAccount} />
    <ProcessTx 
      processing={processing}
      lang={lang}
      steps={steps}
    />

    <Card style={{maxWidth: '450px', margin: 'auto', marginBottom: '10px'}}>
    <Card.Body>
      <div className="sub-text" style={{color: 'black'}}>
        <span>💰</span> {lang === "es" ? "Depositar" : "Deposit"} BUSD <br/>
        <span><b>Total</b> {total} <br/> <b>Allocated</b> {alloc} <br/> <b>Available</b> {busd}</span>
        { busd === 0 && <span></span>}
        <br/>
        <br/>
        <input
          style={{maxWidth: '350px', margin: 'auto', marginBottom: '10px'}}
          type="text"
          value={money}
          placeholder="busd"
          onChange={e => setMoney(e.target.value)}
        />
        <br/>
        <span><button onClick={sendDeposit} disabled={busd<MONEY_MIN_AMOUNT}>Send</button></span>
        <br/>
        <br/>
        <small>{getProject(pid)}</small>
        { ref && <span><br/><br/>&nbsp;Ref {ref}</span> }
      </div>
    </Card.Body>
    </Card>

    <br/>

    <p>
    <span><button onClick={handleChangeNetwork}>{lang==="es"?"recargar red":"reload network"}</button></span> &nbsp;
    <span><button onClick={Logout}>logout</button></span>
    </p>

    </div>
  )}

  /*
  * Added a conditional render! We don't want to show Connect to Wallet if we're already conencted :).
  */
  return (
    <div className="App">
      <Toaster />    
      <div className="container">
        <div className="header-container">
          <img src={logo} className="logo" alt='REDIT coin logo' style={{marginBottom: 10}}/>
          <h2 className="footer-text" style={{marginBottom: 20}}>
            {lang === "es" ? "Panel" : "Dashboard"}
          </h2> 
          {!currentAccount ? renderNotConnectedContainer() : renderAllocateUI()}
        </div>
          
        <div className="footer-container">
          <a href='https://www.reditcoin.com'>https://www.reditcoin.com</a>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
