import React from 'react';
import { BeatLoader, HashLoader } from 'react-spinners';
import {Card} from 'react-bootstrap';

const ProcessTx = ({processing,lang,steps}) => {
  return (
  <>
  { processing>1 &&
    <div style={{color: 'white'}}>
    <BeatLoader color={'#FFFFFF'}/>
    { processing > 0 && <p>
      { lang === "es" ? "1 Conectar billetera..." : "1 Connect wallet..."}
      { processing > 1 && <span>✅</span>}
      { steps.map( (item,id) => (
        <>
        <br />
        { lang === "es" ? id+2 + " " + item + " transacción..." : id+2 + " " + item + " transaction..."}
        { processing>id+2 && <span>✅</span>}
        </>
      ))}
      </p>
    }
    { processing && <p>
      <Card style={{maxWidth: '450px', margin: 'auto', backgroundColor: '#FFF7AA', color: 'black'}}>
      <Card.Body>
        {lang === "es" ? "Las operaciones pueden demorar. Compruebe la actividad en su billetera." : "Operations can take a while. Check your wallet activity."}
        <br />{lang === "es" ? "Paso: " : "Step: "}{processing}/{steps.length+1}
      </Card.Body>
      </Card>
      </p>
    }
    </div>
    }
  </>
  );
};

export default ProcessTx;
