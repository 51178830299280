import React from 'react';

const WalletAddress = ({currentAccount}) => {
  return (
    <p className="sub-text">
    <span>wallet: {currentAccount.slice(0,6)}...{currentAccount.slice(-4)}</span>
    <br/>
    </p>
  );
};

export default WalletAddress
