import toast from 'react-hot-toast';
import { ethers } from "ethers";
const CHAINID = `${process.env.REACT_APP_CHAINID}`;

const checkIfWalletIsConnected = async () => {
  /*
  * First make sure we have access to window.ethereum
  */
  const { ethereum } = window;

  if (!ethereum) {
    console.log("Make sure you have metamask!");
    return;
  } else {
    console.log("We have the ethereum object", ethereum);
  }

  /*
  * Check if we're authorized to access the user's wallet
  */
  const accounts = await ethereum.request({ method: 'eth_accounts' });

  /*
  * User can have multiple authorized accounts, we grab the first one if its there!
  */
  if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      return account;
  } else {
      console.log("No authorized account found")
      return ""
  }
}

const handleChangeNetwork = async () => {
  const { ethereum } = window;
  await ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: CHAINID }] });
  window.location.reload();
}

const handleLogout = async () => {
  const { ethereum } = window;
  await ethereum.request({ method: 'wallet_requestPermissions' });
}

const connectWallet = async (lang) => {
  try {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Get MetaMask!");
      return;
    }

    /*
     * Fancy method to request access to account.
     */
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });

    /*
     * Boom! This should print out public address once we authorize Metamask.
     */
    console.log("Connected", accounts[0]);
    toast.success(lang === "es" ? "Billetera conectada" : "Wallet connected"); 

    handleChangeNetwork();
    return accounts[0];

  } catch (error) {
    toast.error(lang === "es" ? "Error al conectar la billetera." : "Connecting wallet error.");
    console.log(error)
    return "";
  }

} 

const rRound = res => Math.round(ethers.utils.formatEther(res) * 1e2) / 1e2;

export { checkIfWalletIsConnected, handleChangeNetwork, handleLogout, connectWallet, rRound };

