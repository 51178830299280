// import './styles/App.css';
import './styles/AppRedit.css';
import React, { useEffect, useState } from "react";
import { ethers, BigNumber } from "ethers";
import { BeatLoader, HashLoader } from 'react-spinners';
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from 'react-bootstrap';

import WalletAddress from './components/WalletAddress';
import LoadingIndicator from './components/LoadingIndicator';
import ProcessTx from './components/ProcessTx';

// Wallet
import { connectWallet,
         checkIfWalletIsConnected,
         handleChangeNetwork,
         handleLogout,
         rRound } from './utils/Wallet.js';

// Config
import data from './utils/data.json';

// Abi
import presale from './utils/PresaleStaged.json';
import token from './utils/Token.json';
import vault from './utils/TokenVesting.json';
import cdeposit from './utils/DepositStaged.json';
import staking from './utils/Staking.json';

// Constants
const logo = require('./assets/sidebar_logo_1.png');

// Env
const PRESALE_CONTRACT = `${process.env.REACT_APP_PRESALE_CONTRACT}`;
const TOKEN_CONTRACT = `${process.env.REACT_APP_TOKEN_CONTRACT}`;
const VAULT5_CONTRACT = `${process.env.REACT_APP_VAULT5_CONTRACT}`;
const VAULT2_CONTRACT = `${process.env.REACT_APP_VAULT2_CONTRACT}`;
const STAKING_CONTRACT = `${process.env.REACT_APP_STAKING_CONTRACT}`;
const DEPOSIT_CONTRACT = `${process.env.REACT_APP_DEPOSIT_CONTRACT}`;
const CHAINID = `${process.env.REACT_APP_CHAINID}`;

console.log("CHAIN",CHAINID);
console.log("VAULT5",VAULT5_CONTRACT);
console.log("VAULT2",VAULT2_CONTRACT);

const App = () => {

  /*
  * Just a state variable we use to store our user's public wallet. Don't forget to import useState.
  */
  const [currentAccount, setCurrentAccount] = useState("");
  const [lang, setLang] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(0);
  const [vault1, setVault1] = useState(0);
  const [release1, setRelease1] = useState(0);
  const [vault2, setVault2] = useState(0);
  const [release2, setRelease2] = useState(0);
  const [stake, setStake] = useState([]);
  const [redit, setRedit] = useState(0);
  const [steps, setSteps] = useState([]);

  const connect = async () => {
    setProcessing(1);
    const getConnect = async () => {
        const acc = await connectWallet(lang);
        setCurrentAccount(acc);
    };
    getConnect();
    setProcessing(0);
  }

  const Logout = async () => {
    setCurrentAccount(null);
    //handleLogout();
  }

  const fetchAlloc = async () => {

    const { ethereum } = window;

    if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const Contract5 = new ethers.Contract(VAULT5_CONTRACT, vault.abi, provider);
        const Contract2 = new ethers.Contract(VAULT2_CONTRACT, vault.abi, provider);
        const Staking = new ethers.Contract(STAKING_CONTRACT, staking.abi, provider);
        const Token = new ethers.Contract(TOKEN_CONTRACT, token.abi, provider);

        try {
            let r = await Token.balanceOf(currentAccount);
            //setRedit(ethers.utils.formatEther(r));
            setRedit(rRound(r));

            let redit1 = await Contract5.currentBalance(currentAccount);
            setVault1(ethers.utils.formatEther(redit1));

            let rel1 = await Contract5.unlockedBalance(currentAccount);
            setRelease1(ethers.utils.formatEther(rel1));

            let redit2 = await Contract2.currentBalance(currentAccount);
            setVault2(ethers.utils.formatEther(redit2));

            let rel2 = await Contract2.unlockedBalance(currentAccount);
            setRelease2(ethers.utils.formatEther(rel2));

            let pstake1 = await Staking.pendingRewards(0, currentAccount);
            let pstake2 = await Staking.pendingRewards(1, currentAccount);
            let pstake3 = await Staking.pendingRewards(2, currentAccount);

            let rstake1 = await Staking.stakingTokens(0, currentAccount);
            let rstake2 = await Staking.stakingTokens(1, currentAccount);
            let rstake3 = await Staking.stakingTokens(2, currentAccount);

            setStake([rRound(rstake1),
                      rRound(rstake2),
                      rRound(rstake3),
                      rRound(pstake1),
                      rRound(pstake2),
                      rRound(pstake3)]);

        } catch (error) {
            console.log(error);
        }
    } else {
        console.log("Ethereum object doesn't exist!");
    }

    setLoading(false);

    console.log("currentAccount",currentAccount);
    console.log("vault1",vault1);
    console.log("vault2",vault2);    
  };

  const ReleaseStake = async (tmonth, tvault) => {

      const { ethereum } = window;
      setProcessing(2);
      setSteps(['RELEASE','APPROVE','CONFIRM']);

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const Token = new ethers.Contract(TOKEN_CONTRACT, token.abi, signer);
        const Staking = new ethers.Contract(STAKING_CONTRACT, staking.abi, signer);
        const VAULT = (tvault===2) ? VAULT2_CONTRACT : VAULT5_CONTRACT;
        const RELEASE = (tvault===2) ? release2 : release1;
        const Contract = new ethers.Contract(VAULT, vault.abi, signer);

        console.log("vault %s tmonth %s tvault %s release %s",tmonth, tvault, VAULT, RELEASE)

        if (RELEASE==="0.0") {
            toast.error("Nothing to release!");
            return
        }

        // Vesting release
        // Token approve
        // Staking deposit

        let tx1 = await Contract.release();
        const tx1s = await tx1.wait();

        if (tx1s.status === 1) {
            toast.success("Transaction 1 OK");
        } else {
            toast.error("Transaction 1 FAILED");
            return
        }
        setProcessing(3);

        let tx2 = await Token.approve(STAKING_CONTRACT, ethers.utils.parseEther(RELEASE));
        const tx2s = await tx2.wait();

        if (tx2s.status === 1) {
            toast.success("Transaction 2 OK");
        } else {
            toast.error("Transaction 2 FAILED");
            return
        }
        setProcessing(4);

        let tx3 = await Staking.deposit(tmonth, ethers.utils.parseEther(RELEASE));
        const tx3s = await tx3.wait();

        if (tx3s.status === 1) {
            toast.success("Transaction 3 OK");
        } else {
            toast.error("Transaction 3 FAILED");
            return
        }

        await fetchAlloc();
        setProcessing(0);
      }
    }

  const Stake = async (tmonth) => {

      const { ethereum } = window;
      setProcessing(2);
      setSteps(['APPROVE','CONFIRM']);

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const Token = new ethers.Contract(TOKEN_CONTRACT, token.abi, signer);
        const Staking = new ethers.Contract(STAKING_CONTRACT, staking.abi, signer);

        if (redit==="0.0") {
            toast.error("Nothing to release!");
            return
        }

        // Token approve
        // Staking deposit

        let tx1 = await Token.approve(STAKING_CONTRACT, ethers.utils.parseEther(redit));
        const tx1s = await tx1.wait();

        if (tx1s.status === 1) {
            toast.success("Transaction 1 OK");
        } else {
            toast.error("Transaction 1 FAILED");
            return
        }
        setProcessing(3);

        let tx2 = await Staking.deposit(tmonth, ethers.utils.parseEther(redit));
        const tx2s = await tx2.wait();

        if (tx2s.status === 1) {
            toast.success("Transaction 2 OK");
        } else {
            toast.error("Transaction 2 FAILED");
            return
        }

        await fetchAlloc();
        setProcessing(0);
      }
    }

  const Claim = async (tmonth) => {

      const { ethereum } = window;
      setProcessing(2);
      setSteps(['CLAIM']);

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const Token = new ethers.Contract(TOKEN_CONTRACT, token.abi, signer);
        const Staking = new ethers.Contract(STAKING_CONTRACT, staking.abi, signer);

        let pstake = await Staking.pendingRewards(tmonth, currentAccount);

        if (rRound(pstake)<"1.0") {
            toast.error("Too low value to claim!");
            setProcessing(0);
            return
        }

        // Staking claim

        let tx1 = await Staking.claim(tmonth);
        const tx1s = await tx1.wait();

        if (tx1s.status === 1) {
            toast.success("Transaction 1 OK");
        } else {
            toast.error("Transaction 1 FAILED");
            return
        }

        await fetchAlloc();
        setProcessing(0);
      }
    }


  /*
  * This runs our function when the page loads.
  */
  useEffect(() => {
    setLoading(true);

    const getAccount = async () => {
        const acc = await checkIfWalletIsConnected();
        setCurrentAccount(acc);
    };
    getAccount();

  }, [])


  /*
  * Check Allocation
  */
  useEffect(() => {
    if (currentAccount) {
        fetchAlloc();
    }
  }, [currentAccount]);

  /*
  * Set language
  */
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const lang = params.get('lang'); // Returns "full"
    if(lang) setLang(lang.toLowerCase());
  }, []);

  /*
  * We added a simple onClick event here.
  */
  const renderNotConnectedContainer = () => (
    <button onClick={connect} className="cta-button connect-wallet-button">
      {lang === "es" ? "Conectar billetera" : "Connect to Wallet"}
    </button>
  );

  /*
  * We want the "Connect to Wallet" button to dissapear if they've already connected their wallet!
  */
  const renderAllocateUI = () => {
    if (loading) return <LoadingIndicator />;

    return (
    <div>
    <WalletAddress currentAccount={currentAccount} />
    <ProcessTx 
      processing={processing}
      lang={lang}
      steps={steps}
    />

    { vault1>0 &&
    <Card style={{maxWidth: '450px', margin: 'auto', marginBottom: '10px'}}>
    <Card.Body>
      <p className="sub-text" style={{color: 'black'}}>
        <span>🔒</span> {lang === "es" ? "Boveda 5% " : "Vault 5% "}<br/>
        <span><b>Total</b> {vault1} <br/> <b>Pending</b> {release1}</span>
        <br/>
        <span style={{color: 'black'}}>Stake</span>&nbsp;
        <span><button onClick={()=>ReleaseStake(0,5)}>&nbsp;3&nbsp;</button></span> &nbsp;
        <span><button onClick={()=>ReleaseStake(1,5)}>&nbsp;6&nbsp;</button></span> &nbsp;
        <span><button onClick={()=>ReleaseStake(2,5)}>12</button></span> &nbsp;
      </p>
    </Card.Body>
    </Card>
    }

    { vault1>0 && <> <br/> </> }

    { vault2>0 &&
    <Card style={{maxWidth: '450px', margin: 'auto', marginBottom: '10px'}}>
    <Card.Body>
      <p className="sub-text" style={{color: 'black'}}>
        <span>🔒</span> {lang === "es" ? "Boveda 2% " : "Vault 2% "}<br/>
        <span><b>Total</b> {vault2} <br/><b>Pending</b> {release2}</span>
        <br/>
        <span>Stake</span>&nbsp;
        <span><button onClick={()=>ReleaseStake(0,2)}>&nbsp;3&nbsp;</button></span> &nbsp;
        <span><button onClick={()=>ReleaseStake(1,2)}>&nbsp;6&nbsp;</button></span> &nbsp;
        <span><button onClick={()=>ReleaseStake(2,2)}>12</button></span> &nbsp;
      </p>
    </Card.Body>
    </Card> 
    }

    { vault2>0 && <> <br/> </> }

    { redit>0 &&
    <Card style={{maxWidth: '450px', margin: 'auto', marginBottom: '10px'}}>
    <Card.Body>
      <div className="sub-text" style={{color: 'black'}}>
        <span>💵</span> Wallet <br/>
        <span><b>Total</b> {redit}</span>
        <br/>
        <span>Stake</span>&nbsp;
        <span><button onClick={()=>Stake(0)}>&nbsp;3&nbsp;</button></span> &nbsp;
        <span><button onClick={()=>Stake(1)}>&nbsp;6&nbsp;</button></span> &nbsp;
        <span><button onClick={()=>Stake(2)}>12</button></span> &nbsp;
      </div>
    </Card.Body>
    </Card>
    }

    { redit>0 && <> <br/> </> }

    <Card style={{maxWidth: '450px', margin: 'auto', marginBottom: '10px'}}>
    <Card.Body>
      <div className="sub-text" style={{color: 'black'}}>
        <span>🏦</span> Staking<br/>
        <table style={{margin: 'auto'}}>
        <thead>
        <tr><th>Month&nbsp;</th><th>Total&nbsp;</th><th>Pending&nbsp;</th><th>Rate&nbsp;</th></tr>
        </thead>
        <tbody>
        <tr><td>3</td><td>{stake[0]}</td><td>{stake[3]}</td><td>&nbsp;3%</td><td><button onClick={()=>Claim(0)}>📤</button></td></tr>
        <tr><td>6</td><td>{stake[1]}</td><td>{stake[4]}</td><td>7.5%</td><td><button onClick={()=>Claim(1)}>📤</button></td></tr>
        <tr><td>12</td><td>{stake[2]}</td><td>{stake[5]}</td><td>18%</td><td><button onClick={()=>Claim(2)}>📤</button></td></tr>
        </tbody>
        </table>
      </div>
    </Card.Body>
    </Card>

    <br/>

    { vault1==="0.0" && vault2==="0.0" &&
    <p className="sub-text">
    {lang === "es" ? "Sin fondos en bovedas" : "No funds in vaults"}
    <br/>
    <br/>
    </p>
    }

    <p>
    <span><button onClick={handleChangeNetwork}>{lang==="es"?"recargar red":"reload network"}</button></span> &nbsp;
    <span><button onClick={Logout}>logout</button></span>
    </p>

    </div>
  )}

  /*
  * Added a conditional render! We don't want to show Connect to Wallet if we're already conencted :).
  */
  return (
    <div className="App">
      <Toaster />    
      <div className="container">
        <div className="header-container">
          <img src={logo} className="logo" alt='REDIT coin logo' style={{marginBottom: 10}}/>
          <h2 className="footer-text" style={{marginBottom: 20}}>
            {lang === "es" ? "Panel" : "Dashboard"}
          </h2> 
          {!currentAccount ? renderNotConnectedContainer() : renderAllocateUI()}
        </div>
          
        <div className="footer-container">
          <a href='https://www.reditcoin.com'>https://www.reditcoin.com</a>
        </div>
      </div>
    </div>
  );
};

export default App;
