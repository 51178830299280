import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import Deposit from './Deposit';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
     <Route path="/" element={<App />} />
     <Route path="/deposit" element={<Deposit />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
